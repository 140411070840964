<template>
  <div class="profit-profiles">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $vuetify.lang.t('$vuetify.different.profitProfiles') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="selectedProject"
                    :items="compProjects"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.project')"
                    item-value="id"
                    item-text="name"
                    outlined
                  ></v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" md="3">
                  <v-row>
                    <v-col cols="9">
                      <v-select
                        v-model="selectedTemplates"
                        :items="templates"
                        :menu-props="{ maxHeight: '200' }"
                        :label="$vuetify.lang.t('$vuetify.different.profitTemplates')"
                        item-value="id"
                        item-text="name"
                        outlined
                        multiple
                        :disabled="compDisabledApplyTemplateAction"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            fab
                            small
                            v-on="on"
                            v-bind="attrs"
                            :disabled="compDisabledApplyTemplateAction"
                            @click="applyTemplates"
                          >
                            <v-icon>mdi-content-duplicate</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.buttons.addTemplates') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-treeview
                    v-model="selectedCategories"
                    selectable
                    :items="categories"
                  ></v-treeview>
                  <p v-show="!categories.length">{{ $vuetify.lang.t('$vuetify.different.selectProject') }}</p>
                </v-col>
                <v-divider v-show="categories.length" vertical class="hidden-sm-and-down"></v-divider>
                <v-col cols="12" md="9">
                  <v-card flat>
                    <v-card-title>{{ $vuetify.lang.t('$vuetify.user.profiles') }}</v-card-title>
                    <v-toolbar flat color="white">
                      <v-text-field
                        text
                        solo
                        flat
                        clearable
                        prepend-icon="search"
                        :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                        v-model="searchProfile"
                        hide-details
                        class="hidden-sm-and-down"
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            :disabled="compDisabledProfileAction"
                            @click="getProfiles"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.buttons.refresh') }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            color="success"
                            :disabled="compDisabledProfileAction"
                            @click="createProfile"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.buttons.create') }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                            small
                            :disabled="!selectedProfiles.length"
                            @click="dialogDeleteProfile = true"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                            $vuetify.lang.t('$vuetify.buttons.delete')
                          }}</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text class="pa-0">
                      <v-data-table
                        :headers="compHeader"
                        :items="items"
                        fixed-header
                        class="elevation-1"
                        item-key="id"
                        v-model="selectedProfiles"
                        :search="searchProfile"
                        show-select
                        :items-per-page="-1"
                        hide-default-footer
                      >
                        <template v-slot:item.is_percent="{ item }">
                          <v-simple-checkbox
                            v-model="item.is_percent"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                small
                                @click="editProfile(item)"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.buttons.edit') }}</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                small
                                @click="deleteProfile(item)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                                $vuetify.lang.t('$vuetify.buttons.delete')
                              }}</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialogProfile" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ compDialogCardTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="profileForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="profileItem.type"
                    :items="profileTypes"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.type') + '*'"
                    :rules="notEmpty"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="profileItem.name"
                    :label="$vuetify.lang.t('$vuetify.different.title') + '*'"
                    :hint="$vuetify.lang.t('$vuetify.different.title')"
                    :rules="max255"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="profileItem.is_percent"
                        label="%"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        v-model="profileItem.value"
                        type="number"
                        step=".01"
                        min=".01"
                        :label="$vuetify.lang.t('$vuetify.different.value') + '*'"
                        :hint="$vuetify.lang.t('$vuetify.different.value')"
                        :rules="numberRule"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogProfile = false"
            :disabled="loadingProfile"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="storeProfile"
            :disabled="!valid"
            :loading="loadingProfile"
          >
            {{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteProfile" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{
            $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteProfile = false"
            :disabled="loading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteProfileConfirm"
            :loading="loading"
          >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      valid: false,
      loading: false,
      loadingProfile: false,
      dialogProfile: false,
      dialogDeleteProfile: false,
      searchProfile: null,
      categories: [],
      selectedCategories: [],
      selectedProfiles: [],
      items: [],
      templates: [],
      selectedTemplates: [],
      profileTypes: ['purchase', 'net'],
      profileItem: {
        id: null,
        project_id: null,
        category_id: null,
        type: 'purchase',
        name: null,
        is_percent: false,
        value: null,
        sort_order: null,
        created_at: null,
      },
      defaultProfileItem: {
        id: null,
        project_id: null,
        category_id: null,
        type: 'purchase',
        name: null,
        is_percent: false,
        value: null,
        sort_order: null,
        created_at: null,
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      selectedProject: null,
      notEmpty: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      ],
      numberRule: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) => parseFloat(v) >= 0.01 || this.$vuetify.lang.t('$vuetify.validation.incorrect'),
      ],
      max255: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) =>
          (v && v.length <= 255) ||
          this.$vuetify.lang.t('$vuetify.validation.max255')
      ],
    }
  },
  watch: {
    selectedProject(val) {
      if (val) {
        this.getCategories()
      } else this.categories = []
    },
    selectedCategories() {
      this.getProfiles()
    },
    dialogProfile: function (val) {
      if (!val) {
        this.profileItem = Object.assign({}, this.defaultProfileItem)
        this.$refs.profileForm.resetValidation()
      }
    },
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.profitProfiles')
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          responseType: 'application/json'
        }
      }
    },
    compHeader() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.different.type'),
          value: 'type',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name',
        },
        {
          text: '%',
          value: 'is_percent',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.value'),
          value: 'value',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compProjects() {
      return this.$store.getters.getProjects
    },
    compDisabledFormField() {
      return !this.selectedCategories.length
    },
    compDisabledProfileAction() {
      return this.selectedCategories.length !== 1
    },
    compDisabledApplyTemplateAction() {
      return this.selectedCategories.length !== 1
    },
    compDialogCardTitle() {
      return this.profileItem.id
        ? this.$vuetify.lang.t('$vuetify.different.editItem')
        : this.$vuetify.lang.t('$vuetify.different.newItem')
    },
    compAction() {
      return this.profileItem.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    }
  },
  mounted() {
    this.getTemplates()
  },
  methods: {
    getCategories() {
      if (this.selectedProject) {
        this.loading = true
        let data = {
          project_id: this.selectedProject,
          type: 'tree'
        }

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/category', data, this.compAuthHeader)
          .then((response) => {
            if (response.data.success) {
              this.categories = response.data.items
            } else {
              this.categories = []
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.categories = []
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else this.categories = []
    },
    getProfiles() {
      if (this.selectedCategories.length === 1) {
        this.loading = true
        let data = {
          project_id: this.selectedProject,
          category_id: this.selectedCategories[0]
        }

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/profile', data, this.compAuthHeader)
          .then((response) => {
            if (response.status === 200) {
              this.items = response.data.data
            } else {
              this.items = []
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.items = []
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else {
        this.items = []
        if (this.selectedCategories.length > 1) {
          this.showSnackbar(
            'amber',
            this.$vuetify.lang.t('$vuetify.category.selectOneCategory')
          )
        }
      }
    },
    storeProfile() {
      if (this.$refs.profileForm.validate() && this.selectedCategories.length === 1) {
        this.loading = this.loadingProfile = true

        if (!this.profileItem.project_id) this.profileItem.project_id = this.selectedProject
        if (!this.profileItem.category_id) this.profileItem.category_id = this.selectedCategories[0]

        let url = 'api/profile/store'
        if (this.profileItem.id) url = 'api/profile/update'

        this.$axios
          .post(this.$store.getters.getApiUrl + url, this.profileItem, this.compAuthHeader)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.loadingProfile = false
              this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.saved'))
              this.dialogProfile = false
              this.$refs.profileForm.resetValidation()
              this.getProfiles()
            } else {
              this.loading = this.loadingProfile = false
              this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
            }
          })
          .catch(() => {
            this.loading = this.loadingProfile = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          })
      }
    },
    editProfile(item) {
      this.profileItem = Object.assign({}, item)
      this.dialogProfile = true
    },
    deleteProfile(item) {
      this.profileItem = Object.assign({}, item)
      this.dialogDeleteProfile = true
    },
    deleteProfileConfirm() {
      let arrIds = []
      if (this.profileItem.id) arrIds.push(this.profileItem.id)
      else if (this.selectedProfiles.length) {
        this.selectedProfiles.forEach(function (entry) {
          arrIds.push(entry.id)
        })
      }

      if (arrIds.length) {
        this.loading = true
        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/profile/destroy', {ids: arrIds}, this.compAuthHeader)
          .then((response) => {
            if (response.status === 200) {
              this.dialogDeleteProfile = false
              this.profileItem = Object.assign({}, this.defaultProfileItem)
              this.selectedProfiles = []
              this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.deleted'))
              this.getProfiles()
            } else {
              this.loading = false
              this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
            }
          })
          .catch(() => {
            this.loading = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          })
      }
    },
    createProfile() {
      this.profileItem.sort_order = this.items.length + 1
      this.dialogProfile = true
      if (this.$refs.profileForm) this.$refs.profileForm.resetValidation()
    },
    applyTemplates() {
      if (this.selectedCategories.length === 1 && this.selectedProject) {
        let data = {
          project_id: this.selectedProject,
          category_id: this.selectedCategories[0],
          templates_ids: this.selectedTemplates
        }
        this.loading = true

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/profile/templates', data, this.compAuthHeader)
          .then((response) => {
            if (response.status === 200) {
              this.getProfiles()
              this.selectedTemplates = []
            } else {
              this.loading = false
              this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
            }
          })
          .catch(() => {
            this.loading = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          })
      }
    },
    getTemplates() {
      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/profile-template', {}, this.compAuthHeader)
        .then((response) => {
          if (response.status === 200) {
            this.templates = response.data.data
          } else {
            this.templates = []
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
        })
        .catch(() => {
          this.templates = []
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    }
  }
}
</script>
